<template>
  <div
    class="soupe-ui-layout-footer is-dark has-background-light has-text-weight-medium is-size-6">
    ©{{ year }} {{ $t('soupe.ui.fragments.company.name') }}
    <p><i><a class="icp" href="https://beian.miit.gov.cn/">京ICP备20022356号-3</a></i></p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.soupe-ui-layout-footer {
  padding: 20px 200px;
}

.soupe-ui-layout-footer a.icp {
  color: bulmaDarken($grey, 20%)
}

.soupe-ui-layout-footer a.icp:hover {
  text-decoration: underline;
}
</style>

const production = process.env.NODE_ENV === 'production'
const apiConfig = require('../../../api.config')

const state = {
  application_preferences: {}
}

export default {
  namespaced: true,
  state: state,
  getters: {
    get: (state) => (key) => {
      if (state.application_preferences[key]) {
        return state.application_preferences[key]
      }
    }
  },
  mutations: {
    set(state, application_preferences) {
      let defaultLogoutUrl = (!production && apiConfig.serviceAddress !== apiConfig.uiAddress)
        ? apiConfig.serviceAddress + '/security/logout?logout_callback=' + apiConfig.uiAddress + '/security/logout'
        : '/security/logout'

      state.application_preferences = {
        'soupe_ida.oauth2.client.logout_url': defaultLogoutUrl,
        ...application_preferences
      }
    }
  }
}

<template>
  <nav
    aria-label="main navigation"
    class="hero-head navbar soupe-ui-layout-header is-success is-unselectable"
    role="navigation"
  >
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <img alt class="logo" src="@/assets/logo.png"/>
      </a>
    </div>
    <div class="navbar-menu">
      <div class="navbar-start">
        <div class="navbar-item primary-link">
          <a class="navbar-link"
            v-bind:class="{'is-active': $route.matched[0] && $route.matched[0].name.startsWith('workspace')}"
            href="/workspace">
            <div class="is-block">
              {{ $t('titles.workspace') }}
            </div>
          </a>
        </div>
        <div class="navbar-item primary-link">
          <a class="navbar-link"
            v-bind:class="{'is-active': $route.matched[0] && $route.matched[0].name.startsWith('arcade')}"
            href="/arcade">
            <div class="is-block">
              {{ $t('titles.arcade') }}
            </div>
          </a>
        </div>
      </div>
      <div class="navbar-end" v-if="logged_in">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link user-info" href="#">
            <img class="avatar" :src="avatarUrl"/>
          </a>
          <ul class="navbar-dropdown menu-list">
            <li>
              <a :href="userProfileUrl">{{ $t('soupe.ui.fragments.user.user_profile') }}</a>
            </li>
            <li>
              <a :href="logoutLink">{{ $t('soupe.ui.fragments.user.sign_out') }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import md5 from 'md5'
import PageMixin from '@cheeryworks-private/soupe-ui-fragments/src/mixins/page-mixin'

export default {
  mixins: [
    PageMixin
  ],
  data() {
    return {
      workspaces: [
        {
          code: 'superadmin',
          name: 'Superadmin'
        },
        {
          code: 'cheeryworks',
          name: 'CheeryWorks',
          site: 'https://cheeryworks.com',
          email: 'support@cheeryworks.com'
        }
      ],
      primary_links: [],
      logged_in: false
    }
  },
  computed: {
    logoutLink() {
      return this.$store.getters['application_preferences/get']('soupe_ida.oauth2.client.logout_url')
    },
    userProfileUrl() {
      let authServerUrl = this.$store.getters['application_preferences/get']('soupe_ida.client.server_url')

      if (authServerUrl) {
        return authServerUrl + '/user/profile'
      }

      return '/user/profile'
    },
    account() {
      return this.$store.getters['user/get']('account')
    },
    accountName() {
      return this.$store.getters['user/get']('name')
    },
    avatarUrl() {
      let avatarUrl = this.$store.getters['user/get']('avatar_url')

      if (avatarUrl) {
        return avatarUrl
      }

      let avatarHash

      if (this.email) {
        avatarHash = md5(this.email)
      } else if (this.account) {
        avatarHash = md5(this.account)
      } else {
        avatarHash = md5('anonymous')
      }

      return 'https://www.gravatar.com/avatar/' + avatarHash + '?s=128&d=identicon'
    }
  },
  created() {
    if (this.$cookie.get('logged_in')) {
      this.logged_in = true
    }
  },
  mounted() {
    this.loadApplicationPreferences()

    if (!this.logged_in) {
      if (window.location.href.includes('/user/register')) {
        return
      }

      if (!window.location.href.includes('/security/login')) {
        window.location.href = '/security/login'
      }

      return
    }

    this.loadMe()

    this.loadWorkspaces()
  },
  methods: {
    loadLinks() {
      let routeConfigs = this.$router.options.routes

      let primaryLinks = []

      if (this.logged_in) {
        for (let primaryRouteConfig of routeConfigs) {
          if (primaryRouteConfig.meta && primaryRouteConfig.meta.menu) {
            let primaryLink = {
              path: primaryRouteConfig.path,
              name: primaryRouteConfig.name,
              children: []
            }

            primaryLinks.push(primaryLink)

            if (primaryRouteConfig.children) {
              for (let secondaryRouteConfig of primaryRouteConfig.children) {
                if (secondaryRouteConfig.meta && secondaryRouteConfig.meta.menu) {
                  let secondaryLink = {
                    path: secondaryRouteConfig.path,
                    name: secondaryRouteConfig.name,
                    children: []
                  }

                  primaryLink.children.push(secondaryLink)

                  if (secondaryRouteConfig.children) {
                    for (let thirdRouteConfig of secondaryRouteConfig.children) {
                      if (thirdRouteConfig.meta && thirdRouteConfig.meta.menu) {
                        let thirdLink = {
                          path: thirdRouteConfig.path,
                          name: thirdRouteConfig.name
                        }

                        secondaryLink.children.push(thirdLink)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      this.primary_links = primaryLinks
    },
    async loadWorkspaces() {
      for (let i in this.workspaces) {
        let avatarHash

        if (this.workspaces[i].email) {
          avatarHash = md5(this.workspaces[i].email)
        } else {
          avatarHash = md5(this.workspaces[i].code)
        }

        if (this.workspaces[i].code === this.$route.params.workspace) {
          if (this.workspaces[i].email) {
            this.$cookie.set('workspace_email', this.workspaces[i].email)
          } else {
            this.$cookie.delete('workspace_email')
          }

          if (this.workspaces[i].site) {
            this.$cookie.set('workspace_site', this.workspaces[i].site)
          } else {
            this.$cookie.delete('workspace_site')
          }
        }

        this.workspaces[i].avatar = 'https://www.gravatar.com/avatar/' + avatarHash + '?s=128&d=identicon'
      }


    },
    async setCurrentWorkspace(workspace) {
      if (workspace) {
        this.$cookie.set('workspace', workspace)

        window.location.href = '/' + workspace
      } else {
        this.$cookie.delete('workspace')

        window.location.href = '/'
      }
    },
    async loadApplicationPreferences() {
      let self = this

      self.$axios
        .get('/service/application/preferences')
        .then(function(response) {
          if (response.data) {
            self.$store.commit('application_preferences/set', response.data)
          }
        })
        .catch(() => {
        })
    },
    async loadMe() {
      let self = this

      self.$axios
        .get('/me')
        .then(function(response) {
          if (response.data) {
            self.$store.commit('user/set', response.data)
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
.navbar-item:hover,
.navbar-link:hover {
  background-color: transparent !important;
  color: $white !important;
}

.navbar-item,
.navbar-link {
  font-weight: 600;
}

.primary-link .navbar-link.is-active {
  background-color: transparent !important;
}

.primary-link .navbar-link:not(.is-arrowless) {
  padding-right: 10px;
}

.primary-link .navbar-link:not(.is-arrowless)::after {
  display: none;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: bulmaDarken($white, 15%) !important;
}

.navbar-link .is-block {
  border-bottom: 2px solid transparent;
}

.navbar-link:hover .is-block,
.navbar-link.is-active .is-block {
  border-bottom: 2px solid $white;
}

.navbar-item.has-dropdown:focus .navbar-link:not(.is-arrowless)::after,
.navbar-item.has-dropdown:hover .navbar-link:not(.is-arrowless)::after {
  border-color: $white !important;
}

.navbar-end .menu-list:last-child {
  left: auto;
  right: 0;
}

.navbar-dropdown {
  margin-top: -10px;
  border-radius: $radius;
  overflow: hidden;
  background-color: $light;
}

.navbar-item.has-dropdown:focus .navbar-link,
.navbar-item.has-dropdown:hover .navbar-link,
.navbar-item.has-dropdown.is-active .navbar-link {
  background-color: transparent !important;
}

.menu-list {
  word-break: keep-all;
  white-space: nowrap;
}

.menu-list li ul {
  margin-top: 0px;
}

.menu-list a:hover {
  background-color: transparent;
}

.menu-list a {
  font-weight: 600;
}

.menu-list a.is-active {
  color: $white;
  background-color: transparent;
  font-weight: 600;
}

.logo {
  max-height: 45px;
}

.divided {
  border-top: 2px solid bulmaDarken($white, 15%);
}

.workspace-link {
  display: flex;
  align-items: center;
}

.workspace-link img {
  margin-right: 8px;
  border-radius: 50%;
  border: 2px solid $white;
  max-width: 30px;
  max-height: 30px;
}

.avatar {
  max-height: 30px;
  border-radius: 50%;
  border: 3px solid $grey;
}

.navbar-item.has-dropdown:focus .avatar,
.navbar-item.has-dropdown:hover .avatar {
  border-color: $white;
}
</style>

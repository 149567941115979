import Vue from 'vue'
import Vuex from 'vuex'
import application_preferences from './modules/application_preferences'
import user from '@cheeryworks-private/soupe-ui-fragments/src/store/modules/user'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    application_preferences,
    user
  },
  strict: debug
})
